.container_map {
    width: 100%;
    height: 100%;
    box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.1);
    border: solid 1px #efefef;
    margin-left: 10px;
    display: flex;
    flex-direction: row;
    border-radius: 5px;
};

.container_map_content {
    width: 80%;
    margin-left: 20px;
    margin-top: 10px;
    display: flex;
    flex-direction: column;
};

.container_map_content_close {
    width: 100px;
    cursor: pointer;
    margin-left: auto;
    margin-bottom: auto;
    margin-right: 10px;
    text-transform: uppercase;
    color: #1d68d9;
    padding: 2px;
    border: 1px solid #1d68d9;
    border-radius: 5px;
    text-transform: uppercase;
    text-align: center;
    transition: .2s ease-in-out;
};

.container_map_content_close:hover {
    background-color: rgb(196, 196, 196);
};

.container_map_content_data {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    font-size: 18px;
};

.container_map_content_data div {
    padding: 2px 2px 0 0;
}

.map_root {
    border-radius: 5px;
}


.comment {
    display: flex;  
    width: 90%;
    flex-direction: column;
}

.comment_input {
    width: 100%;
    height: 100px;
    border: 1px solid black;
    border-radius: 5px;
}

.comment_title {
    font-size: 18px;
    margin-bottom: 5px;
}

.comment_buttons {
    display: flex;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 20px;
    margin-top: 20px
}

.comment_buttons div {
    border: 1px solid black;
    padding: 10px;
    border-radius: 5px;
    transition: .2s ease-in-out;
    cursor: pointer;
};

.comment_apply {
    background-color: green;
    color: white;
};

.comment_apply:hover {
    background-color: rgb(0, 172, 0);
};

.comment_back {
    background-color: brown;
    color: white;
};

.comment_back:hover {
    background-color: rgb(214, 55, 55);
};

.info_input {
    width: 60%;
    display: flex;
    margin-left: auto;
    margin-right: 200px;
};