.container_list_pagination {
    width: 100%;
    height: 60px;
    margin-top: auto;
    display: flex;
    align-items: center;
    flex-direction: column;
    border-bottom: 1px solid #c7c7c7;
};

.container_list_pagination_main {
    padding-top: 5px;
    width: 100%;
    display: flex; 
    justify-content: space-around;
    margin-top: auto;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
};

.container_list_pagination_back {
    cursor: pointer;
    transition: .2s ease-in-out;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    text-align: center;
    margin-bottom: 5px;
};

.container_list_pagination_back:hover {
    background-color: #c7c7c7;
};

.container_list_pagination_forward {
    cursor: pointer;
    transition: .2s ease-in-out;
    display: flex;
    justify-content: center;
    border-radius: 5px;
    align-items: center;
    text-align: center;
    margin-bottom: 5px;
};

.container_list_pagination_forward:hover {
    background-color: #c7c7c7;
};

.container_list_pagination_page {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 5px;
    transition: .2s ease-in-out;
    margin-bottom: 5px;
    border-radius: 5px;
};

.container_list_pagination_page:hover {
    background-color: #c7c7c7;
};


.container_list_pagination_page.active {
    cursor: pointer;
    background-color: #1d68d9;
    color: white;   
};