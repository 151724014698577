.client_list_region {
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: auto;
};

.client_list_region_region {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 49px;
    min-height: 49px;
    width: 100%;
    background-color: white;
    border-bottom: 1px solid;
    border-bottom-color: #c7c7c7;
    color: black;
    cursor: pointer;
};

.client_list_region_region:hover {
    background-color: #c7c7c7;
    transition: .2s ease-in-out;

    .arrow:not(.open) {
        animation: hover 2s linear infinite;
    };

};

.client_list_region_region span {
    padding-left: 10px;
};

.client_list_region_region span:nth-child(2) {
    margin-left: auto;
    padding-left: 0px;
    padding-right: 10px;
};

.arrow {
    transition: 0.5s ease-in-out;
};

@keyframes hover { 50% { margin-right: 10px; } 100% {} }

.client_list_region_clients {
    height: 100%;
    display: flex;
    flex-direction: column;
};

.client_list_region_clients_back {
    cursor: pointer;
    width: 60%;
    margin-left: auto;
    margin-right: auto;
    color: blue;
    margin-top: 10px;
    margin-bottom: 10px;
    border: 1px solid black;
};

.client_list_region_clients_data {
    margin-left: auto;
    margin-right: auto;
    border-bottom: 1px solid black;
};