.marker {
    cursor: pointer;
    z-index: 99999;
}

.marker_description {
    z-index: 999999;
    position: absolute;
    width: 200px;
    height: 50px;
    background-color: white;
    border: 1px solid #c7c7c7;
    border-radius: 5px;
    padding-left: 5px;
    cursor: default;
    font-size: 14px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.marker_description.hidden {
    visibility: hidden;
}

.marker_description_text {
    display: flex;
    margin-bottom: auto;
}

.marker_description_close {
    width: 10px;
    height: 20px;
    justify-content: center;
    margin-left: auto;
    text-align: center;
    align-items: center;
    cursor: pointer;
    color: #af272f;
    margin-right: 2px;
}


.marker_description_change {
    width: 70px;
    cursor: pointer;
    color: #1d68d9;
}