.container_list_client {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    height: 49px;
    min-height: 49px;
    background-color: white;
    border-bottom: 1px solid;
    border-bottom-color: #c7c7c7;
    color: black;
    cursor: pointer;
};

.container_list_client span {
    text-decoration: none;
    color: black;
}

.container_list_client.active {
    background-color: rgb(179, 179, 179);
    transition: .2s ease-in-out;
}

.container_list_client:not(.active):hover {
    background-color: #c7c7c7;
    transition: .2s ease-in-out;
};

.container_list_client span {
    padding-left: 10px;
};

.container_list_client_description {
    display: flex;
    flex-direction: column;
    margin-left: auto;
    text-transform: uppercase;
    color: #1d68d9;
    margin-right: 10px;
    font-size: 14px;
    padding: 5px;
    cursor: pointer;
};

.container_list_client_description:hover {
    .container_list_client_description_way {
        width: 100%;
    };
};

.container_list_client_description_way {
    width: 0px;
    height: 1px;
    transition: .2s ease-in-out;
    background-color: black;
};

.container_list_clients {
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: auto;
};