.container_list {
    width: 300px;
    height: 100%;
    box-shadow: 0 20px 20px 0 rgba(0, 0, 0, 0.2);
    border: solid 1px #efefef;
    display: flex;
    flex-direction: column;
    color: black;
};

.container_list_switcher {
    width: 100%;
    height: 50px;
    display: flex;
    flex-direction: row;
    cursor: pointer;
    margin-bottom: 1px;
};

.container_list_switcher_option {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    border-bottom: 1px solid;
    border-bottom-color: #efefef;
    transition: .3s ease-in-out;
};

.container_list_switcher_option.selected {
    background-color: #1d68d9;
    border-bottom-color: #1d68d9;
    color: white;
};

.container_list_switcher_option:not(.selected):hover {
    background-color: #c7c7c7;
    border-bottom-color: #c7c7c7;
};

.container_list_wrapper {
    overflow: auto;
    display: flex;
    flex-direction: column;
    height: 100%;
;}

.container_list_wrapper::-webkit-scrollbar {
    width: 5px;
    background-color: #bbbbbb;
};

.container_list_wrapper::-webkit-scrollbar-thumb{
    background-color: #808080;
};